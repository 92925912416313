import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import NavbarMenu from "../Navbar/NavbarMenu";
import SidebarMenu from "../Sidebar/SidebarMenu";

const LayoutDashboard = () => {
  const location=useLocation()
  const containerStyle = {
    minHeight: `100vh`,
  };

  const routerValidation = ()=>{
    if(!localStorage.getItem('userToken') || localStorage.getItem('userToken') ==''){
      return <Navigate to={'/login'}/>
    }
    return <Outlet/>
  }

  useEffect(()=>{
    routerValidation()
  },[location?.pathname])

  return (
    <div className="d-flex">
        <div className="sticky-top">
          <SidebarMenu />
        </div>
      <div className="d-flex flex-column flex-grow-1 ">
        <div className="sticky-top">
          <NavbarMenu />
        </div>
        <div className="mobile-outlet" style={containerStyle}>
          {/* <Outlet /> */}
          {routerValidation()}
        </div>
      </div>
    </div>
  );
};

export default LayoutDashboard;
