import axios from "axios";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URLs } from "./ApiUrls";

const InactivityHandler = ({ timeout = 300000 }) => { 
  const navigate = useNavigate();
  const location = useLocation()
  let timer;

  const resetTimer = () => {
    clearTimeout(timer);
    timer = setTimeout(logoutUser, timeout);
  };

  const handleLogout = () => {
    axios.post(API_URLs.logout,{
      refreshToken: localStorage.getItem('userTokenOut')
    },{
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`
      }
    })
    .then((res)=>{
      localStorage.clear();
      navigate("/login");
    })
    .catch((err)=>{
      localStorage.removeItem("userToken");
    })
  };

  const logoutUser = () => {
    if(localStorage.getItem('userToken')){
    // localStorage.removeItem("userToken");
    handleLogout()
    alert("You have been logged out due to inactivity.");
    navigate("/login"); 
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer);

    console.log('timeout',timeout)
    resetTimer();

    return () => {
      clearTimeout(timer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("click", resetTimer);
    };
  }, []);

  return null;
};

export default InactivityHandler;
